import { createContext, useState } from "react";
import { Outlet } from "react-router-dom";

import InstitutionSmallWhite from "common/assets/svg/sidebar/InstitutionSmallWhite";
import ProfileSmallWhite from "common/assets/svg/sidebar/ProfileSmallWhite";

import logo from "../../assets/svg/logo/logo.svg";
import CollapseButton from "./CollapseButton";
import SidebarItem from "./SidebarItem";

export const SidebarContext = createContext({
  expanded: false,
});

const sidebar_items = [
  {
    icon: <ProfileSmallWhite />,
    title: "Менеджеры",
    route: "/in/managers",
    badge: 0,
  },
  {
    icon: <InstitutionSmallWhite />,
    title: "Заведения",
    route: "/in/institutions",
    badge: 0,
  },
];

const Sidebar = () => {
  // State
  const [expanded, setExpanded] = useState(true);

  // Handlers
  const toggleExpand = () => setExpanded((prev) => !prev);

  return (
    <div className="flex overflow-y-hidden">
      <aside className="h-screen bg-light-black-3 pt-6">
        <nav className="flex h-full flex-col px-3">
          {/* Header */}
          <div className="group mb-3 flex h-10 w-full items-center rounded-item px-1.5">
            <CollapseButton onClick={toggleExpand} isCollapsed={expanded} />
            <img
              src={logo}
              alt="logo"
              className={`resize-none overflow-hidden transition-all duration-300 ${expanded ? "ml-3 h-6" : "h-0"}`}
            />
          </div>
          {/* Items */}
          <div className="flex flex-col items-center gap-y-2">
            <SidebarContext.Provider value={{ expanded }}>
              {sidebar_items.map((item) => (
                <SidebarItem
                  route={item.route}
                  key={item.route}
                  badge={item.badge}
                  icon={item.icon}
                >
                  {item.title}
                </SidebarItem>
              ))}
            </SidebarContext.Provider>
          </div>
        </nav>
      </aside>
      <Outlet />
    </div>
  );
};

export default Sidebar;
