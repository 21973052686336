import { ReactNode } from "react";

type Props = {
  children: ReactNode;
  className?: string;
};

const H1B = ({ children, className }: Props) => {
  return (
    <h1 className={`${className} text-[26px] font-bold leading-[31.69px]`}>
      {children}
    </h1>
  );
};

const H1M = ({ children, className }: Props) => {
  return (
    <h1 className={`${className} text-[26px] font-medium leading-[31.69px]`}>
      {children}
    </h1>
  );
};

const H2S = ({ children, className }: Props) => {
  return (
    <h2 className={`${className} text-[20px] font-semibold leading-[24.4px]`}>
      {children}
    </h2>
  );
};

const H2M = ({ children, className }: Props) => {
  return (
    <h2 className={`${className} text-[20px] font-medium leading-[24.4px]`}>
      {children}
    </h2>
  );
};

const H2R = ({ children, className }: Props) => {
  return (
    <h2 className={`${className} text-[20px] font-normal leading-[24.4px]`}>
      {children}
    </h2>
  );
};

const TextXlS = ({ children, className }: Props) => {
  return (
    <span className={`${className} text-[20px] font-semibold leading-[24.4px]`}>
      {children}
    </span>
  );
};

const TextXlR = ({ children, className }: Props) => {
  return (
    <span className={`${className} text-[20px] font-normal leading-[24.4px]`}>
      {children}
    </span>
  );
};

const TextLgS = ({ children, className }: Props) => {
  return (
    <span className={`${className} text-[18px] font-semibold leading-[22px]`}>
      {children}
    </span>
  );
};

const TextLgM = ({ children, className }: Props) => {
  return (
    <span className={`${className} text-[18px] font-medium leading-[22px]`}>
      {children}
    </span>
  );
};

const TextLgR = ({ children, className }: Props) => {
  return (
    <span className={`${className} text-[18px] font-normal leading-[22px]`}>
      {children}
    </span>
  );
};

const TextBaseS = ({ children, className }: Props) => {
  return (
    <span className={`${className} text-[16px] font-semibold leading-[20px]`}>
      {children}
    </span>
  );
};

const TextBaseM = ({ children, className }: Props) => {
  return (
    <span className={`${className} text-[16px] font-medium leading-[20px]`}>
      {children}
    </span>
  );
};

const TextBaseR = ({ children, className }: Props) => {
  return (
    <span className={`${className} text-[16px] font-normal leading-[20px]`}>
      {children}
    </span>
  );
};

const TextSmS = ({ children, className }: Props) => {
  return (
    <span className={`${className} text-[14px] font-semibold leading-[17px]`}>
      {children}
    </span>
  );
};

const TextSmM = ({ children, className }: Props) => {
  return (
    <span className={`${className} text-[14px] font-medium leading-[17px]`}>
      {children}
    </span>
  );
};

const TextSmR = ({ children, className }: Props) => {
  return (
    <span className={`${className} text-[14px] font-normal leading-[17px]`}>
      {children}
    </span>
  );
};

const TextXsR = ({ children, className }: Props) => {
  return (
    <span className={`${className} text-[12px] font-normal leading-[15px]`}>
      {children}
    </span>
  );
};

const Text2XsS = ({ children, className }: Props) => {
  return (
    <span className={`${className} text-[10px] font-semibold leading-[12px]`}>
      {children}
    </span>
  );
};

const Text2XsM = ({ children, className }: Props) => {
  return (
    <span className={`${className} text-[10px] font-medium leading-[12px]`}>
      {children}
    </span>
  );
};

const Text2XsR = ({ children, className }: Props) => {
  return (
    <span className={`${className} text-[10px] font-normal leading-[12px]`}>
      {children}
    </span>
  );
};

export {
  H1B,
  H1M,
  H2S,
  H2M,
  H2R,
  TextXlS,
  TextXlR,
  TextLgS,
  TextLgM,
  TextLgR,
  TextBaseS,
  TextBaseM,
  TextBaseR,
  TextSmS,
  TextSmM,
  TextSmR,
  TextXsR,
  Text2XsS,
  Text2XsM,
  Text2XsR,
};
