import { FC, useState } from "react";

import ChevronLeftWhiteSmall from "common/assets/svg/sidebar/ChevronLeftWhiteSmall";
import MenuSmallBlack from "common/assets/svg/sidebar/MenuSmallBlack";

type Props = {
  isCollapsed: boolean;
  onClick: () => void;
};

const CollapseButton: FC<Props> = ({ isCollapsed, onClick }) => {
  const containerStyle = isCollapsed ? "bg-light-black-2" : "bg-white";
  const [hover, setHover] = useState<boolean>();
  return (
    <button
      onClick={onClick}
      onMouseLeave={() => setHover(false)}
      onMouseEnter={() => setHover(true)}
      className={`w-30px h-30px rounded-xs flex items-center justify-center transition-all duration-300 hover:opacity-100 ${containerStyle}`}
    >
      {isCollapsed ? (
        <ChevronLeftWhiteSmall />
      ) : (
        <div>
          <MenuSmallBlack hover={hover} />
        </div>
      )}
    </button>
  );
};

export default CollapseButton;
