const AnimatedLogo = () => {
  return (
    <svg
      width="799"
      height="265"
      viewBox="0 0 799 265"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <style>
        {`
          path {
            stroke-dasharray: 1000;
            stroke-dashoffset: 1000;
            animation: draw 1.5s forwards;
          }
          @keyframes draw {
            to {
              stroke-dashoffset: 0;
            }
          }
          .delay-1 { animation-delay: 0.2s; }
          .delay-2 { animation-delay: 0.4s; }
          .delay-3 { animation-delay: 0.6s; }
          .delay-4 { animation-delay: 0.8s; }
          .delay-5 { animation-delay: 1s; }
          .delay-6 { animation-delay: 1.2s; }
          .delay-7 { animation-delay: 1.4s; }
        `}
      </style>

      {/* Circle */}
      <path
        d="M282.201 132.596C282.201 201.018 226.734 256.485 158.312 256.485C89.8894 256.485 34.4223 201.018 34.4223 132.596C34.4223 64.174 89.8894 8.70688 158.312 8.70688C226.734 8.70688 282.201 64.174 282.201 132.596Z"
        stroke="white"
        strokeWidth="16.012"
        fill="none"
      />

      {/* Inside circle elements */}
      <path
        className="delay-1"
        d="M312.191 158.785C240.435 90.7208 136.285 41.0476 93.1794 24.719L88.8372 22.9549L76.7604 34.6247L80.6955 35.8459C194.896 78.7254 284.961 149.332 315.719 179.275C317.564 167.117 314.135 160.549 312.191 158.785Z"
        fill="none"
        stroke="white"
        strokeWidth="2"
      />
      <path
        className="delay-2"
        d="M273.002 221.531C202.501 151.28 99.2749 99.0781 56.4744 81.7584L52.1651 79.8929L39.8771 91.4982L43.7893 92.8064C157.188 138.307 245.95 211.463 276.155 242.353C278.222 230.069 274.914 223.353 273.002 221.531Z"
        fill="none"
        stroke="white"
        strokeWidth="2"
      />
      <path
        className="delay-3"
        d="M303.541 199.602C227.35 124.683 116.761 70.0083 70.9907 52.0355L66.38 50.0939L53.5566 62.9387L57.735 64.2829C178.995 111.48 274.628 189.196 307.287 222.155C309.247 208.772 305.606 201.543 303.541 199.602Z"
        fill="none"
        stroke="white"
        strokeWidth="2"
      />
      <path
        className="delay-1"
        d="M4.6922 158.785C76.4475 90.7208 180.598 41.0476 223.703 24.719L228.046 22.9549L240.122 34.6247L236.187 35.8459C121.987 78.7254 31.9216 149.332 1.16414 179.275C-0.681307 167.117 2.74724 160.549 4.6922 158.785Z"
        fill="none"
        stroke="white"
        strokeWidth="2"
      />
      <path
        className="delay-2"
        d="M43.8809 221.531C114.382 151.28 217.608 99.0781 260.408 81.7584L264.718 79.8929L277.006 91.4982L273.093 92.8064C159.694 138.307 70.9332 211.463 40.7275 242.353C38.6604 230.069 41.9684 223.353 43.8809 221.531Z"
        fill="none"
        stroke="white"
        strokeWidth="2"
      />
      <path
        className="delay-3"
        d="M13.3417 199.602C89.5329 124.683 200.122 70.0083 245.892 52.0355L250.503 50.0939L263.326 62.9387L259.148 64.2829C137.887 111.48 42.2544 189.196 9.59554 222.155C7.636 208.772 11.2765 201.543 13.3417 199.602Z"
        fill="none"
        stroke="white"
        strokeWidth="2"
      />

      {/* Letters */}
      <path
        className="delay-4"
        d="M424.157 12.0733L465.22 111.372H447.302L439.537 92.707H385.781L378.016 111.372H360.247L401.161 12.0733H424.157ZM391.754 78.2228H433.564L415.048 33.277C414.302 31.3358 413.854 29.2453 413.854 27.1548L413.704 22.3765H411.614V26.7068C411.614 28.9467 411.166 31.0372 410.27 33.1277L391.754 78.2228Z"
        fill="none"
        stroke="white"
        strokeWidth="2"
      />
      <path
        className="delay-5"
        d="M535.543 37.906V36.8607H552.117V136.16H535.543V74.1911C535.543 60.4535 523.149 49.2544 507.62 49.2544C492.239 49.2544 479.696 60.4535 479.696 74.1911C479.696 87.7794 488.058 98.9786 498.362 98.9786C508.665 98.9786 517.027 89.7206 517.027 78.2228H533.452C533.452 97.6347 517.773 113.463 498.362 113.463C478.95 113.463 463.271 95.8428 463.271 74.1911C463.271 52.3902 483.131 34.7702 507.62 34.7702C514.638 34.7702 521.357 36.2634 527.181 38.8019C531.063 40.4444 534.049 43.5802 535.841 47.3132L538.678 53.8834L540.769 52.9875L537.036 44.6254C536.14 42.5349 535.543 40.2951 535.543 37.906Z"
        fill="none"
        stroke="white"
        strokeWidth="2"
      />
      <path
        className="delay-6"
        d="M595.208 54.3313L647.471 111.372H627.91L584.159 63.7386L579.231 67.9196V111.372H562.806V12.0733H579.231V42.087C579.231 44.1775 578.932 46.268 578.036 48.2092L576.245 52.9875L578.186 53.8834L579.231 51.4942C580.127 48.9558 581.769 46.7159 583.86 45.0734L622.684 12.0733H645.082L595.208 54.3313Z"
        fill="none"
        stroke="white"
        strokeWidth="2"
      />
      <path
        className="delay-7"
        d="M667.734 111.372H651.309V36.8607H667.734V111.372ZM667.734 32.6797H651.309V16.2543H667.734V32.6797Z"
        fill="none"
        stroke="white"
        strokeWidth="2"
      />
      <path
        className="delay-7"
        d="M694.42 111.372H677.994V36.8607H694.42V111.372ZM694.42 32.6797H677.994V16.2543H694.42V32.6797Z"
        fill="none"
        stroke="white"
        strokeWidth="2"
      />
      <path
        className="delay-7"
        d="M723.942 94.3496C722.599 95.9921 720.807 97.1867 719.015 98.2319L715.282 100.024L716.327 101.965L718.865 100.621C721.255 99.5758 723.793 98.9786 726.332 98.9786H763.363V111.372H701.395V98.9786L738.725 54.0327C740.219 52.3902 742.01 50.8969 743.952 49.8517L747.386 48.2092L746.341 46.268L743.653 47.6119C741.413 48.6571 738.875 49.2544 736.336 49.2544H701.395V36.8607H763.363V49.2544L723.942 94.3496Z"
        fill="none"
        stroke="white"
        strokeWidth="2"
      />
      <path
        className="delay-4"
        d="M423.898 152.14L464.795 251.037H446.949L439.216 232.447H385.678L377.944 251.037H360.247L400.995 152.14H423.898ZM391.626 218.022H433.267L414.826 173.258C414.082 171.325 413.636 169.243 413.636 167.161L413.488 162.402H411.406V166.715C411.406 168.945 410.959 171.027 410.067 173.109L391.626 218.022Z"
        fill="none"
        stroke="white"
        strokeWidth="2"
      />
      <path
        className="delay-5"
        d="M533.816 152.14H550.323V251.037H533.816V214.006C533.816 200.324 521.472 189.171 506.006 189.171C490.688 189.171 478.196 200.324 478.196 214.006C478.196 227.54 486.524 238.693 496.785 238.693C507.047 238.693 515.375 229.473 515.375 218.022H531.734C531.734 237.355 516.118 253.119 496.785 253.119C477.452 253.119 461.837 235.57 461.837 214.006C461.837 192.294 481.616 174.745 506.006 174.745C512.995 174.745 519.688 176.232 525.487 178.761C529.354 180.396 532.328 183.52 534.113 187.237L536.939 193.781L539.021 192.889L535.303 184.561C534.41 182.479 533.816 180.248 533.816 177.868V152.14Z"
        fill="none"
        stroke="white"
        strokeWidth="2"
      />
      <path
        className="delay-6"
        d="M649.008 174.745C668.341 174.745 684.105 190.509 684.105 209.842V251.037H667.598V209.842C667.598 198.391 659.27 189.171 649.008 189.171C638.747 189.171 630.419 200.324 630.419 214.006V251.037H614.06V209.842C614.06 198.391 605.732 189.171 595.47 189.171C585.209 189.171 576.881 200.324 576.881 214.006V251.037H560.522V176.827H576.732C576.583 178.017 576.434 179.058 576.137 180.099L571.824 193.037L573.906 193.632L576.286 186.494C577.922 181.289 582.234 177.273 587.44 175.786C590.116 175.191 592.793 174.745 595.47 174.745C606.475 174.745 616.439 179.95 622.834 187.981C629.229 179.95 638.598 174.745 649.008 174.745Z"
        fill="none"
        stroke="white"
        strokeWidth="2"
      />
      <path
        className="delay-7"
        d="M709.477 251.037H693.118V176.827H709.477V251.037ZM709.477 172.663H693.118V156.304H709.477V172.663Z"
        fill="none"
        stroke="white"
        strokeWidth="2"
      />
      <path
        className="delay-7"
        d="M764.013 174.745C783.346 174.894 798.961 190.658 798.961 209.991V251.037H782.453V209.842C782.453 198.391 774.125 189.171 763.864 189.171C748.546 189.171 736.054 200.324 736.054 214.006V251.037H719.695V176.827H736.054V177.868C736.054 180.248 735.608 182.479 734.715 184.561L730.997 192.889L732.931 193.781L735.905 187.237C737.541 183.52 740.515 180.545 744.233 178.909C750.182 176.232 756.874 174.745 764.013 174.745Z"
        fill="none"
        stroke="white"
        strokeWidth="2"
      />
    </svg>
  );
};

export default AnimatedLogo;
