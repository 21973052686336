import { Navigate } from "react-router-dom";

import Sidebar from "common/components/Sidebar/Sidebar";

import LoginPage from "modules/auth/pages/LoginPage";
import InstitutionsPage from "modules/institutions/InstitutionsPage";

import UsersPage from "../../modules/managers/UsersPage";

const routes = [
  { path: "/", element: <LoginPage />, index: true },
  {
    path: "/in",
    element: <Sidebar />,
    children: [
      { path: "/in/institutions", element: <InstitutionsPage />, index: true },
      { path: "/in/managers", element: <UsersPage /> },
      { path: "/in", element: <Navigate to="institutions" />, index: true },
    ],
  },
  { path: "*", element: <Navigate to="/" /> }, // This handles redirection for any unmatched paths under /in
];

export default routes;
