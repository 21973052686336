import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import routes from "./routrer";

const router = createBrowserRouter(routes);

function App() {
  return (
    <main>
      <RouterProvider router={router} />
    </main>
  );
}

export default App;
