import React from "react";

import UserRegistrationForm from "./components/UserRegistrationForm";

const UsersPage: React.FC = () => {
  return (
    <div className="flex min-h-screen w-full bg-light-black-1">
      {/* Предполагается, что здесь уже есть сайдбар */}
      <div className="flex-1 p-8">
        <h1 className="mb-8 font-montserrat text-3xl font-bold text-bright-orange">
          Управление пользователями
        </h1>
        <UserRegistrationForm />
      </div>
    </div>
  );
};

export default UsersPage;
