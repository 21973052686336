import { ReactSVGElement, SVGProps } from "react";

const ChevronLeftWhiteSmall = ({ className }: SVGProps<ReactSVGElement>) => {
  return (
    <svg
      className={`${className} stroke-white`}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0454 13.7727L6.27271 9.00002L11.0454 4.22729"
        strokeWidth="1.36364"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ChevronLeftWhiteSmall;
