import api from "./instance";

export type ApiCallback<D, T> = (args: {
  success: boolean;
  error: string;
  data: D;
}) => T;

export type ApiRequest<D, T> = {
  callback: ApiCallback<D, T>;
};

export type AuthRequestParams = {
  email: string;
  password: string;
} & ApiRequest<unknown, unknown>;

const loginRequest = ({ email, password, callback }: AuthRequestParams) => {
  api.post("/auth/login", { email, password }).then((response) => {
    if (response.status === 201) {
      callback({ success: true, data: response.data, error: "" });
    } else {
      callback({
        success: false,
        data: response.data,
        error: "Неправильный логин или пароль",
      });
    }
  });
};

export { loginRequest };
