import { ReactNode, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// import AppTooltip from "common/components/AppTooltip.tsx";
import { Text2XsM, TextBaseR, TextSmS } from "common/typography";

import { SidebarContext } from "./Sidebar";

type SidebarItemProps = {
  icon: ReactNode;
  children: string;
  badge?: number;
  route: string;
};

const Badge = ({
  children,
  expanded,
}: {
  children: number;
  expanded: boolean;
}) => {
  return (
    <div
      className={`${expanded ? "min-h-[24px] min-w-[24px]" : "h-3.5 min-w-[14px]"} flex w-auto items-center justify-center transition-all`}
    >
      <div
        className={`${expanded ? "min-h-[20px] min-w-[20px]" : "h-3.5 min-w-[14px]"} flex items-center justify-center rounded-full bg-red px-1 transition-all`}
      >
        {expanded ? (
          <TextSmS className="text-white">{children}</TextSmS>
        ) : (
          <Text2XsM className="text-white">{children}</Text2XsM>
        )}
      </div>
    </div>
  );
};

const SidebarItem = ({ icon, children, badge, route }: SidebarItemProps) => {
  const { expanded } = useContext(SidebarContext);
  const navigate = useNavigate();
  const path = useLocation().pathname;
  const onClick = () => {
    navigate(route, { replace: true });
  };
  const active = path.endsWith(route);

  return (
    <li
      onClick={onClick}
      className={`${!active ? "bg-light-black-3 hover:bg-light-black-2 active:bg-light-black-1" : "bg-light-black-4"} group relative flex h-10 cursor-pointer items-center rounded-item px-2.5 transition-all duration-300`}
    >
      <div>{icon}</div>

      {/*{!expanded && <AppTooltip type={"right"}>{children}</AppTooltip>}*/}

      <TextBaseR
        className={`${expanded ? "ml-2.5 h-6 w-[178px]" : "h-0 w-0"} flex items-center overflow-hidden whitespace-nowrap text-white transition-all duration-300`}
      >
        {children}
      </TextBaseR>

      {!!badge && (
        <div className={`${expanded ? "right-3" : "right-2.5 top-2"} absolute`}>
          <Badge expanded={expanded}>{badge}</Badge>
        </div>
      )}
    </li>
  );
};

export default SidebarItem;
