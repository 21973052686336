import { AlertCircle, Lock, User } from "lucide-react";
import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { H1B, TextBaseM } from "../../../common/typography";
import { ApiCallback, loginRequest } from "../../../core/apis/endpoints";

const LoginPage = () => {
  const [form, setForm] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState("");
  const [formErrors, setFormErrors] = useState({
    email: "",
    password: "",
  });

  const navigate = useNavigate();

  const onChangeForm = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setFormErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const validateForm = () => {
    let isValid = true;
    const errors = { email: "", password: "" };

    if (!form.email.trim()) {
      errors.email = "Поле логина не может быть пустым";
      isValid = false;
    }

    if (!form.password.trim()) {
      errors.password = "Поле пароля не может быть пустым";
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  const afterLogin: ApiCallback<unknown, unknown> = ({
    error,
    data,
    success,
  }) => {
    if (success) {
      console.log(data);
      setError("");
      navigate("/in/institutions");
    } else {
      setError(error);
    }
  };

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (validateForm()) {
      loginRequest({ ...form, callback: afterLogin });
    } else {
      setError("Пожалуйста, заполните все поля");
    }
  };

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => setError(""), 6000);
      return () => clearTimeout(timer);
    }
  }, [error]);

  return (
    <div className="flex min-h-screen items-center justify-center bg-light-black-1 font-montserrat">
      <div className="w-full max-w-md rounded-item bg-light-black-3 p-8 shadow-lg">
        <H1B className="mb-8 text-center text-2xl font-bold text-white">
          Вход в систему
        </H1B>
        <form className="space-y-6" onSubmit={onSubmit}>
          <div className="space-y-2.5">
            <div className="space-y-1">
              <div className="relative">
                <input
                  name="email"
                  onChange={onChangeForm}
                  value={form.email}
                  placeholder="Логин"
                  className={`w-full rounded-xs bg-light-black-2 px-4 py-3 text-white focus:outline-none focus:ring-2 focus:ring-accent-orange ${
                    formErrors.email ? "ring-2 ring-red" : ""
                  }`}
                />
                <User
                  className="absolute right-3 top-1/2 -translate-y-1/2 transform text-dark-grey"
                  size={20}
                />
              </div>
              {formErrors.email && (
                <p className="text-xs text-red">{formErrors.email}</p>
              )}
            </div>
            <div className="space-y-1">
              <div className="relative">
                <input
                  name="password"
                  onChange={onChangeForm}
                  value={form.password}
                  type="password"
                  placeholder="Пароль"
                  className={`w-full rounded-xs bg-light-black-2 px-4 py-3 text-white focus:outline-none focus:ring-2 focus:ring-accent-orange ${
                    formErrors.password ? "ring-2 ring-red" : ""
                  }`}
                />
                <Lock
                  className="absolute right-3 top-1/2 -translate-y-1/2 transform text-dark-grey"
                  size={20}
                />
              </div>
              {formErrors.password && (
                <p className="text-xs text-red">{formErrors.password}</p>
              )}
            </div>
          </div>
          {error && (
            <div className="flex items-center space-x-2 rounded-xs bg-dark-secondary-red p-3 text-red">
              <AlertCircle size={20} />
              <p className="text-sm">
                {error.length > 100 ? error.substring(0, 97) + "..." : error}
              </p>
            </div>
          )}
          <button
            type="submit"
            className="w-full rounded-xs bg-accent-orange px-4 py-3 text-white transition-colors duration-300 hover:bg-light-accent-orange focus:outline-none focus:ring-2 focus:ring-accent-orange focus:ring-offset-2"
          >
            <TextBaseM>Войти</TextBaseM>
          </button>
        </form>
        <div className="mt-6 text-center">
          <a
            href="#"
            className="text-bright-orange transition-colors duration-300 hover:text-light-accent-orange"
          >
            Забыли пароль?
          </a>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
