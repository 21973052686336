import React, { useEffect, useState } from "react";

import AnimatedLogo from "common/assets/svg/animated/AnimatedLogo";

const InstitutionsPage = () => {
  const [loading, setLoading] = useState(true);
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimate(true);
      setTimeout(() => setLoading(false), 1500);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="relative min-h-screen w-screen bg-white">
      <div className="px-4 pt-8">
        <input type="text" className="" /> {/* here */}
      </div>

      {loading && (
        <div
          className={`fixed inset-0 z-50 flex items-center justify-center bg-light-black-1 transition-all duration-1000 ease-in-out ${
            animate ? "opacity-0" : "opacity-100"
          }`}
        >
          <div
            className={`duration-1500 transition-all ease-in-out ${
              animate ? "animate-logo-shrink" : ""
            }`}
          >
            <AnimatedLogo />
          </div>
        </div>
      )}
    </div>
  );
};

export default InstitutionsPage;
