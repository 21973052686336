import { Send } from "lucide-react";
import React, { useState } from "react";

type FormData = {
  email: string;
};

const UserRegistrationForm: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({ email: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState("");

  const validateEmail = (email: string): boolean => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(email);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");
    setIsSuccess(false);

    if (!validateEmail(formData.email)) {
      setError("Пожалуйста, введите корректный email адрес");
      return;
    }

    setIsLoading(true);
    try {
      // Здесь должен быть ваш API запрос
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Имитация API запроса
      setIsSuccess(true);
      setFormData({ email: "" });
    } catch (err) {
      setError("Произошла ошибка при отправке. Попробуйте снова.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="animate-fadeIn w-full max-w-md rounded-item bg-light-black-2 p-8 shadow-lg">
      <h2 className="animate-slideDown mb-6 font-montserrat text-2xl font-bold text-bright-orange">
        Регистрация нового менеджера
      </h2>
      <p className="animate-slideDown animation-delay-150 mb-8 font-montserrat text-grey">
        Введите email адрес нового менеджера. На указанный адрес будет
        отправлено письмо с инструкциями по завершению регистрации.
      </p>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="animate-slideUp animation-delay-300">
          <label
            htmlFor="email"
            className="mb-2 block font-montserrat text-grey"
          >
            Email менеджера
          </label>
          <div className="relative">
            <input
              type="email"
              id="email"
              value={formData.email}
              onChange={(e) =>
                setFormData({ ...formData, email: e.target.value })
              }
              className="animate-pulse-focus w-full rounded-xs border border-light-black-4 bg-light-black-3 px-4 py-3 font-montserrat text-white transition duration-300 focus:outline-none focus:ring-2 focus:ring-accent-orange"
              placeholder="Введите email нового менеджера"
              required
            />
          </div>
        </div>
        <button
          type="submit"
          disabled={isLoading}
          className={`animate-pulse-hover flex w-full items-center justify-center rounded-xs bg-accent-orange px-6 py-3 font-montserrat text-white transition duration-300 hover:bg-light-accent-orange ${
            isLoading ? "cursor-not-allowed opacity-50" : ""
          }`}
        >
          {isLoading ? (
            <span className="animate-spin">
              <svg
                className="h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </span>
          ) : (
            <>
              Отправить приглашение{" "}
              <Send className="ml-2 h-5 w-5 animate-bounce" />
            </>
          )}
        </button>
      </form>
      {error && (
        <p className="animate-shake mt-6 font-montserrat text-red">{error}</p>
      )}
      {isSuccess && (
        <div className="animate-fadeInUp mt-6 rounded-xs border border-green bg-light-black-3 p-4">
          <p className="font-montserrat text-green">
            Приглашение успешно отправлено! Новый менеджер получит инструкции по
            регистрации на указанный email.
          </p>
        </div>
      )}
    </div>
  );
};

export default UserRegistrationForm;
